<template>
  <div class="flex flex-col w-full">
        <div class="w-full bg-gray-50 text-gray-600">
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Type</div>
            <div class="text-xs">Basic info like Make, Model, body type, colors and physical aspects of the vehicle</div>
          </div>
          <div class="mx-6 mt-2 p-2 pb-4 bg-white rounded-lg flex flex-col">
            <div class="flex flex-row w-full pr-3">
              <div class="ml-2 mt-2 w-1/2">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Brand and Model</label>
                </div>
                <div v-if="manual()" class="w-full mt-1 flex flex-row">
                  <div class="relative w-1/2">
                    <t-rich-select
                        :options="this.general.makes"
                        v-model="makeId"
                        placeholder="Search for a brand"
                        value-attribute="id"
                        text-attribute="name"
                    ></t-rich-select>
                  </div>
                  <div class="ml-4 relative w-1/2">
                    <t-rich-select
                        :options="models"
                        v-model="modelId"
                        placeholder="Search for a model"
                        value-attribute="id"
                        text-attribute="name"
                    ></t-rich-select>
                  </div>
                </div>
                <div v-else class="mt-1 relative rounded-sm shadow-sm w-full flex flex-row">
                  <input :value="(nil(lead().condition.car.makeText) + ' ' + nil(lead().condition.car.modelText)).trim()" type="text" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 border-l-4 border-gray-300 rounded" readonly>
                  <button v-on:click="triggerManual" class="-ml-1 relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-r-md text-gray-400 bg-gray-50 hover:text-gray-400 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-300 transition ease-in-out duration-150">
                    <i class="far fa-hand-paper"></i>
                  </button>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/2">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Version</label>
                  <span class="text-sm ml-2 leading-5 text-gray-300 font-light hover:text-blue-500 cursor-pointer" id="email-optional"><i class="fas fa-info-circle"></i></span>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input v-model="lead().condition.car.versionText" type="text" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 border-l-4 border-gray-300 rounded">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400 text-xs">
                    <i class="far fa-asterisk"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row mt-2 pb-2 pr-3">
              <div class="ml-2 mt-2 w-1/4">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Exterior color</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.colorExteriorId"
                      :options="general.colorsexterior"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/4">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Specific</label>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input v-model="lead().condition.car.colorExteriorText" type="text" class="form-input block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 rounded border-gray-300">
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/4"></div>
              <div class="ml-4 mt-2 w-1/4"></div>
            </div>
            <div class="flex flex-row mt-2 pb-2 pr-3">
              <div class="ml-2 mt-2 w-1/4">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Interior color</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.colorInteriorId"
                      :options="general.colorsinterior"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/4">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Interior Specific</label>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input type="text" v-model="lead().condition.car.colorInteriorText" class="form-input block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 rounded border-gray-300">
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/4">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Covering</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.coveringInteriorId"
                      :options="general.coveringsinterior"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/4"></div>
            </div>
          </div>
        </div>
        <div class="w-full bg-gray-50 text-gray-600">
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Form</div>
            <div class="text-xs">Body, doors, places, wheel basis, weight capacity</div>
          </div>
          <div class="mx-6 mt-2 p-2 pb-4 bg-white rounded-lg flex flex-col">
            <div class="flex flex-row pr-3 w-full">
              <div class="ml-2 mt-2 w-1/5">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Body type</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.bodyTypeId"
                      :options="general.bodies"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Doors</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.doorCount"
                      :options="general.doors"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Places</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.seatCount"
                      :options="general.seats"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Wheel basis mm.</label>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input v-model="lead().condition.car.wheelbase" type="number" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 text-right rounded border-gray-300" >
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Weight capacity kg.</label>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input  v-model="lead().condition.car.weightCapacity" type="number" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 text-right rounded border-gray-300">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full bg-gray-50 text-gray-600 pb-4">
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Engine</div>
            <div class="text-xs">Fuel, gearbox power, emission, capacity and more.</div>
          </div>
          <div class="mx-6 mt-2 p-2 pb-4 bg-white rounded-lg flex flex-col">
            <div class="flex flex-row pr-3 w-full">
              <div class="ml-2 mt-2 w-1/5">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Fuel type</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.fuelTypeId"
                      :options="general.fuels"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Gearbox</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.transmissionId"
                      :options="general.gearboxes"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Drive</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.driveTypeId"
                      :options="general.wheelformulas"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>

            </div>
            <div class="flex flex-row mt-2 pr-3 w-full">
              <div class="ml-2 mt-2 w-1/6">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Power HP</label>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input v-model="lead().condition.car.powerHp" type="number" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 text-right rounded border-gray-300">
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Kw.</label>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input v-model="lead().condition.car.powerKw" type="number" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 text-right rounded border-gray-300">
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Fiscal</label>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input v-model="lead().condition.car.powerFiscal" type="number" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 text-right rounded border-gray-300">
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Emission norm</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.emissionNormId"
                      :options="general.emissionnorms"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Emission CO<sub>2</sub></label>
                </div>
                <div class="mt-1 relative rounded-sm shadow-sm w-full">
                  <input v-model="lead().condition.car.emissionCO2" type="number" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 text-right rounded border-gray-300">
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <div class="flex justify-start">
                  <label class="block text-sm font-medium leading-5 text-gray-700">Measuring type</label>
                </div>
                <div class="mt-1 relative w-full">
                  <t-select
                      v-model="lead().condition.car.emissionTypeId"
                      :options="general.emissiontypes"
                      value-attribute="id"
                      text-attribute="name"
                  ></t-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import {nil} from "@/common";

export default {
  name: "Vehicle",
  data() {
    return {
      loading: true,
      general: {},
      generalBase: {},
      makeModelId : 0,
      makeId : 0,
      models: [],
      modelId : 0,
      makeModelOptions: []
    }
  },
  methods: {
    nil(item) {
      return nil(item);
    },
    lead() {
      return this.$store.getters['seller/lead'];
    },
    manual() {
      if(nil(this.makeId)!==0) return true;
      if(nil(this.lead().condition.car.makeText)!=="" && (this.lead().condition.car.makeId===undefined||this.lead().condition.car.makeId==null||this.lead().condition.car.makeId===0)) {
        return false;
      }
      return true;
    },
    triggerManual() {
      this.makeId = -1;
    },
    setFirstRegistration(date) {
      this.lead().condition.car.firstRegistrationDate = date.toDate();
    },
    onChangeMakeModel(value) {
      if(value[0] === "0") return;
      if(value.length>=1) {
        const make = _.head(_.filter(this.general.makes, (make) => { return make.key == value[0] }));
        this.lead().condition.car.makeText = make.name;
      }
      if(value.length>=2) {
        const model = _.head(_.filter(this.general.models, (model) => { return model.key == value[1] }));
        this.lead().condition.car.modelText = model.name;
      }
    },
    onChangeExterior(value) {
      console.log(value);
    },
    onChangeCovering(value) {
      console.log(value);
    },
    onChangeInterior(value) {
      console.log(value);
    },
    onChangeBody(value) {
      console.log(value);
    },
    onChangeDoors(value) {
      console.log(value);
    },
    onChangeSeats(value) {
      console.log(value);
    },
    onChangeFuel(value) {
      console.log(value);
    },
    onChangeGearbox(value) {
      console.log(value);
    },
    onChangeDrive(value) {
      console.log(value);
    },
    onChangeEmissionNorm(value) {
      console.log(value);
    },
    onChangeEmissionType(value) {
      console.log(value);
    },
    signalLoaded() {
      this.fillFields();
      if(nil(this.lead().condition.car.colorExteriorId).length === 8 || this.lead().condition.car.colorExteriorId===0) { this.general.colorsexterior.unshift({id: 0, key: '0', name: this.lead().condition.car.colorExteriorText }); this.lead().condition.car.colorExteriorId = 0; }
      if(nil(this.lead().condition.car.colorInteriorId).length === 8 || this.lead().condition.car.colorInteriorId===0) { this.general.colorsinterior.unshift({id: 0, key: '0', name: this.lead().condition.car.colorInteriorText }); this.lead().condition.car.colorInteriorId = 0; }
      if(nil(this.lead().condition.car.coveringInteriorId).length === 8 || this.lead().condition.car.coveringInteriorId===0) { this.general.coveringsinterior.unshift({id: 0, key: '0', name: this.lead().condition.car.coveringInteriorText }); this.lead().condition.car.coveringInteriorId = 0; }
      if(nil(this.lead().condition.car.bodyTypeId).length === 8 || this.lead().condition.car.bodyTypeId===0) { this.general.bodies.unshift({id: 0, key: '0', name: this.lead().condition.car.bodyTypeText }); this.lead().condition.car.bodyTypeId = 0; }
      if(nil(this.lead().condition.car.fuelTypeId).length === 8 || this.lead().condition.car.fuelTypeId===0) { this.general.fuels.unshift({id: 0, key: '0', name: this.lead().condition.car.fuelTypeText }); this.lead().condition.car.fuelTypeId = 0; }
      if(nil(this.lead().condition.car.transmissionId).length === 8 || this.lead().condition.car.transmissionId===0) { this.general.gearboxes.unshift({id: 0, key: '0', name: this.lead().condition.car.transmissionText }); this.lead().condition.car.transmissionId = 0; }
      if(nil(this.lead().condition.car.driveTypeId).length === 8 || this.lead().condition.car.driveTypeId===0) { this.general.wheelformulas.unshift({id: 0, key: '0', name: this.lead().condition.car.driveTypeText }); this.lead().condition.car.driveTypeId = 0; }
      if(nil(this.lead().condition.car.emissionNormId).length === 8 || this.lead().condition.car.emissionNormId===0) { this.general.emissionnorms.unshift({id: 0, key: '0', name: this.lead().condition.car.emissionNormText }); this.lead().condition.car.emissionNormId = 0; }
      this.loading = false;
    },
    fillFields() {
      this.general = _.cloneDeep(this.generalBase);
      let models = [];
      _.each(this.general.makes, (make) => {
        let mk = {
          'id': make.id,
          'value': make.key,
          'label': make.name,
          'children': []
        };
        _.each(_.filter(this.general.models, (model) => { return model.make_id === mk.id }), (model) => {
          mk.children.push({
            'value': model.key,
            'label': model.name
          });
        });
        models.push(mk);
      });
      this.makeModelOptions = models;
    },
    selectMenu(menu) {
      this.$emit('select-detail-menu', menu);
    }
  },
  watch: {
      makeId: function (newValue) {
        let models = _.filter(this.general.models, (m) => m.make_id == newValue);
        this.models = models;
      }
  //   'lead().condition.car.powerHp': function(value, oldValue) {
  //     this.lead().condition.car.powerKw = Math.round(this.lead().condition.car.powerHp  / 1.359622);
  //   },
  //   'lead().condition.car.powerKw': function(value, oldValue) {
  //     this.lead().condition.car.powerHp = Math.round(this.lead().condition.car.powerKw * 1.359622);
  //   },
  },
  created() {
    if(this.$store.getters['tradein/vehicleItems']!==null) {
      this.generalBase = this.$store.getters['tradein/vehicleItems'];
      this.signalLoaded();
    } else {
      axios.get('/manager/fleet/general/all/').then(response => {
        if (response.status === 200) this.$store.commit('tradein/setVehicleItems', response.data);
        this.generalBase = response.data;
        this.signalLoaded();
      });
    }
  },
}
</script>

<style scoped>

</style>